import React from "react";
import {
	Box,
	Button,
	Checkbox,
	Backdrop,
	TextField,
	CircularProgress,
	FormControlLabel,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	notification,
	notificationTypes
} from "../../utils/notification";
import {useSelector} from "react-redux";
import agent from "../../agent/agent";
import getTrans from "../../languages/getTrans";

const FormFeedBack = () => {
	const classes = useStyles();
	const profile = useSelector((state) => state?.profile?.user);

	const [isTerm, setTerm] = React.useState(true);
	const [isLoading, setLoading] = React.useState(false);

	const [message, setMessage] = React.useState("");
	const handleChangeMessage = ({ target }) => {
		setMessage(target?.value || "")
	};
	const handleSubmit = async () => {
		if (!message) {
			notification({
				type: notificationTypes.error,
				message: getTrans('feedback.Введите ваше сообщение')
			})
			return
		}

		setLoading(true);
		const body = {
			"type": "Обратная связь",
			"name": profile?.name || "",
			"phone": profile?.phone || "",
			"message": message,
			"email": profile?.email || "",
			"source": "tg-app",
			"nophone": 1,
			"g-recaptcha-response": "g-recaptcha-response",
		};
		const res = await agent.post('/api/form/send', body).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: getTrans('error.Ошибка сервера')}
		});
		if (res?.error) {
			setLoading(false);
			notification({message: res?.error, type: notificationTypes.error});
			return
		}

		setMessage("");
		setLoading(false);
		notification({
			type: notificationTypes.success,
			message: getTrans('feedback.Обращение успешно отправлено')
		})
	};

	return (
		<Box
			className={classes.root}
		>
			<div className={classes.title}>
				{getTrans('feedback.Обратная связь')}
			</div>
			<TextField
				rows={4}
				value={message}
				multiline={true}
				fullWidth={true}
				variant="outlined"
				placeholder={getTrans('feedback.Ваше сообщение')}
				className={classes.textField}
				onChange={handleChangeMessage}
			/>
			<Box mt={1}/>
			<FormControlLabel
				value={isTerm}
				checked={isTerm}
				control={<Checkbox />}
				label={<React.Fragment>
					<div
						dangerouslySetInnerHTML={{
							__html: getTrans('feedback.Политика со ссылкой')
						}}
					/>
				</React.Fragment>}
				className={classes.formControlLabel}
				onChange={(e, v) => setTerm(v)}
			/>
			<Box mt={1}/>
			<Button
				fullWidth={true}
				disabled={!isTerm}
				variant="contained"
				onClick={handleSubmit}
			>{getTrans('common.Отправить')}</Button>

			<Backdrop open={isLoading}>
				<CircularProgress/>
			</Backdrop>
		</Box>
	)
}

const useStyles = makeStyles((theme) => ({
	root: {
		padding: "16px 12px",
		boxSizing: "border-box",
		background: theme.palette.background.section,
	},
	title: {
		marginBottom: 14,

		fontSize: 18,
		color: theme.palette.text.main,
		fontWeight: "600",
		lineHeight: "120%"
	},
	textField: {
		"& .MuiInputBase-root": {
			padding: 0
		}
	},
	formControlLabel: {
		"& .MuiFormControlLabel-label": {
			fontSize: 14,
			lineHeight: 1.2,

			"& a": {
				color: theme.palette.text.link,
				textDecoration: "underline"
			}
		}
	}
}));

export default FormFeedBack
